import styles from './SkinnyFormSection.module.scss'
import {
  Slider,
  Grid,
  Paper,
  FormControlLabel,
  Radio,
  RadioGroup,
  Divider,
  Box,
  Button,
} from '@achieve/sunbeam'
import { Typography, LinkButton, MediaImageStandard } from 'components/Contentful'
import { TitleDivider } from 'components/TitleDivider'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { useMemoizedContentGetter } from 'utils/contentful'
import { useEffect, useRef, useState } from 'react'
import { get as _get } from 'lodash-es'
import { ANCHOR_POINTS, AchieveBadge } from 'components/AchieveBadge'
import { HandwrittenText } from 'components/HandwrittenText'
import { AchieveLink } from 'components/AchieveLink'
import { CarouselV2 } from 'components/Carousels/CarouselV2'

const PaperCardBadge = (
  badgeExample,
  exampleTitle,
  exampleLabelOne,
  exampleValueOne,
  exampleLabelTwo,
  exampleValueTwo,
  exampleLabelThree,
  exampleValueThree,
  exampleLabelFour,
  exampleValueFour
) => {
  return (
    <Paper className={styles['example-paper']} data-testid="example-container">
      <AchieveBadge
        content={badgeExample?.mediaContent}
        height={70}
        width={70}
        anchor={ANCHOR_POINTS.RIGHT_TOP}
        translateX={-110}
        translateY={0}
      />
      <Grid container>
        <Grid item xs={12}>
          <Typography
            content={exampleTitle?.textContent}
            variant="displayXS30"
            fontWeight="bold"
            data-testid="example-title"
            className={styles['example-title']}
          />
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Typography
              content={exampleLabelOne?.textContent}
              variant="displayXS30"
              data-testid="example-label-one"
            />
            <Typography
              content={exampleValueOne?.textContent}
              variant="displayS10"
              fontWeight="bold"
              className={styles['example-value']}
              data-testid="example-value-one"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              content={exampleLabelTwo?.textContent}
              variant="displayXS30"
              data-testid="example-label-two"
            />
            <Typography
              content={exampleValueTwo?.textContent}
              variant="displayS10"
              fontWeight="bold"
              className={styles['example-value']}
              data-testid="example-value-two"
            />
          </Grid>
        </Grid>
        <Grid container className={styles['example-divider']}>
          <Grid item xs={6}>
            <Typography
              content={exampleLabelThree?.textContent}
              variant="displayXS30"
              data-testid="example-label-three"
            />
            <Typography
              content={exampleValueThree?.textContent}
              variant="displayS10"
              fontWeight="bold"
              className={styles['example-value']}
              data-testid="example-value-three"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              content={exampleLabelFour?.textContent}
              variant="displayXS30"
              data-testid="example-label-four"
            />
            <Typography
              content={exampleValueFour?.textContent}
              variant="displayS10"
              fontWeight="regular"
              className={styles['example-value']}
              data-testid="example-value-four"
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

const CardSkinnyCarousel = ({ item }) => {
  return (
    <>
      {item?.fields?.cardBadgeImage && (
        <AchieveBadge
          content={item?.fields?.cardBadgeImage}
          height={80}
          width={70}
          anchor={ANCHOR_POINTS.RIGHT_TOP}
          translateX={-60}
          translateY={-8}
        />
      )}
      <Box className={styles['graph-card-container']}>
        <Box>
          <Typography
            content={item?.fields?.cardTitleText}
            className={styles['graph-card-title']}
            variant="displayXS30"
            fontWeight="bold"
          />
          <Divider className={styles['graph-card-divider-titles']} />
          {item?.fields?.cardSubtitleText && (
            <Typography
              content={item?.fields?.cardSubtitleText}
              className={styles['graph-card-subtitle']}
              variant="displayXS20"
              fontWeight="regular"
            />
          )}
        </Box>
        <Box>
          <Box className={styles['graph-card-chart']}>
            <Box className={styles['graph-card-chart-item']}>
              <Typography
                content={item?.fields?.cardJson?.graph?.[0]?.valueText}
                className={styles['graph-card-graph-valueText']}
                variant="displayXS20"
                fontWeight="regular"
              />
              <Box className={styles['graph-card-graph-bar']}></Box>
            </Box>
            <Box className={styles['graph-card-chart-item']}>
              <Typography
                content={item?.fields?.cardJson?.graph?.[1]?.valueText}
                className={styles['graph-card-graph-valueText']}
                variant="displayXS20"
                fontWeight="regular"
              />
              <Box
                className={styles['graph-card-graph-bar-secondary']}
                data-graph={item?.fields?.fieldName}
              />
            </Box>
          </Box>
          <Box className={styles['graph-card-chart-legends']}>
            <Typography
              content={item?.fields?.cardJson?.graph?.[0]?.legendText}
              className={styles['graph-card-graph-legend-text']}
              variant="displayXS20"
              fontWeight="regular"
            />

            <Typography
              content={item?.fields?.cardJson?.graph?.[1]?.legendText}
              className={styles['graph-card-graph-legend-text']}
              variant="displayXS20"
              fontWeight="regular"
            />
          </Box>
        </Box>
        <Box
          className={styles['graph-card-marker']}
          data-variant={item?.fields?.cardJson?.markerVariant}
        >
          <Box
            className={styles['graph-card-marker-image']}
            data-variant={item?.fields?.cardJson?.markerVariant}
          >
            <MediaImageStandard
              alt={item?.fields?.cardImage?.fields?.title}
              content={item?.fields?.cardImage}
              layout="responsive"
            />
          </Box>
          <Typography
            content={item?.fields?.cardText}
            className={styles['graph-card-cardText']}
            variant="displayXS20"
            fontWeight="regular"
          />
          {item?.fields?.cardImageAlternative && (
            <Box
              className={styles['graph-card-marker-image']}
              data-variant={item?.fields?.cardJson?.markerVariant}
            >
              <MediaImageStandard
                alt={item?.fields?.cardImageAlternative?.fields?.title}
                content={item?.fields?.cardImageAlternative}
                layout="responsive"
              />
            </Box>
          )}
        </Box>
        {item?.fields?.cardJson?.helpText && (
          <Typography
            content={item?.fields?.cardJson?.helpText}
            className={styles['graph-card-help-text']}
            variant="displayXS20"
            fontWeight="regular"
          />
        )}
      </Box>
    </>
  )
}

const PaperCarouselCards = (gridItems, trackingNameCarouselControls) => {
  return (
    <CarouselV2
      gridItems={gridItems}
      ItemComponentCarousel={CardSkinnyCarousel}
      variant="cards"
      trackingNameCarouselControls={trackingNameCarouselControls}
    />
  )
}

function SkinnyFormSection({ content, onChangeSection }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)

  const {
    title,
    summary,
    eyebrow,
    exampleTitle,
    exampleLabelOne,
    exampleLabelTwo,
    exampleLabelThree,
    exampleLabelFour,
    exampleValueOne,
    exampleValueTwo,
    exampleValueThree,
    exampleValueFour,
    disclaimer,
    badgeExample,
    config,
    handwrittenRules,
    grid,
  } = useMemoizedContentGetter(content, [
    'title',
    'summary',
    'eyebrow',
    'exampleTitle',
    'exampleLabelOne',
    'exampleLabelTwo',
    'exampleLabelThree',
    'exampleLabelFour',
    'exampleValueOne',
    'exampleValueTwo',
    'exampleValueThree',
    'exampleValueFour',
    'disclaimer',
    'badgeExample',
    'config',
    'handwrittenRules',
    'grid',
  ])
  const buttonActionImg = _get(handwrittenRules, 'gridItems[0]', null)
  const slider = config?.jsonContent?.slider || {
    min: 0,
    max: 100000,
    step: 1000,
    maxPlus: true,
  }
  const sliderQueryParam = config?.jsonContent?.sliderQueryParam || 'loan_amount'
  const ctaLinkBase =
    config?.jsonContent?.ctaLinkBase || '/get-started?brand=achieve&section=achieve_dr'
  const variant = {
    title: isMobile ? 'displayS20' : 'displayM20',
    titleFontWeight: 'medium',
    subtitle: 'displayS10',
    subtitleFontWeight: 'regular',
  }
  const sliderQuestion = config?.jsonContent?.sliderQuestion || 'How much debt do you have?'
  const sliderStatsTitle = config?.jsonContent?.sliderStatsTitle || 'Do you own your home?'
  const ctaText = config?.jsonContent?.ctaText || 'Get started'
  const ctaCustomizeText = config?.jsonContent?.ctaCustomizeText || 'Customize your plan'
  const ctaCustomizeLink =
    config?.jsonContent?.ctaCustomizeLink || '/get-started?brand=achieve&section=achieve_dr'
  const trackingNameCarouselControls = config?.jsonContent?.trackingNameCarouselControls
  const sectionVariation = config?.jsonContent?.sectionVariation || null
  const formVariation = config?.jsonContent?.formVariation || null
  const [sliderValue, setSliderValue] = useState(config?.jsonContent?.initialValue || 50000)
  const [ctaHref, setCtaHref] = useState('')
  const dollarUSLocale = Intl.NumberFormat('en-US')
  const [ownHome, setOwnHome] = useState(null)
  const [changeSlider, setChangeSlider] = useState(false)
  const trackRefButton = useRef()

  useEffect(() => {
    setCtaHref(`${ctaLinkBase}&${sliderQueryParam}=${sliderValue}&is_home_owner=${ownHome}`)
  }, [sliderValue, ctaLinkBase, ownHome, sliderQueryParam])

  function handleChange(e) {
    setSliderValue(e.target.value)
    setChangeSlider(true)
  }
  function handleChangeHome(e) {
    setOwnHome(e?.target?.value)
  }

  function ButtonContainer({ disabled = false }) {
    return formVariation === 'recommendedCards' ? (
      <Grid item xs={12} className={styles['button-container']}>
        <AchieveLink
          track={{
            list_name: 'PRODUCT FORM',
            click_id: ctaText,
            click_text: `Achieve-Web |  ${ctaText}`,
            form_ss_amount: sliderValue,
            form_ss_homeowner: ownHome,
            track_event: 'internal_campaign_click',
          }}
          disabled={disabled}
          noLink
        >
          <div ref={trackRefButton}></div>
        </AchieveLink>
        <Button
          color="primary"
          variant="contained"
          data-testid="skinny-form-cta"
          disabled={disabled}
          fullWidth={isMobile}
          onClick={() => {
            trackRefButton?.current?.click()
            onChangeSection({
              formAmount: sliderValue,
              formHomeowner: ownHome,
              formAmountQueryParameter: sliderQueryParam,
            })
          }}
        >
          <Typography content={ctaText} variant="displayS10" fontWeight="bold" />
        </Button>
      </Grid>
    ) : (
      <Grid item xs={12} className={styles['button-container']}>
        <LinkButton
          track={{
            list_name: 'PRODUCT FORM',
            click_id: ctaText,
            click_text: `Achieve-Web |  ${ctaText}`,
            hero_card_text: title,
            form_ss_amount: sliderValue,
            form_ss_homeowner: ownHome,
          }}
          content={{
            linkHref: ctaHref,
            linkText: ctaText,
          }}
          typographicOptions={{ variant: 'displayS10', fontWeight: 'bold' }}
          color="primary"
          variant="contained"
          data-testid="skinny-form-cta"
          disabled={disabled}
          fullWidth={isMobile}
        />
        {!isMobile && buttonActionImg && (
          <div className={styles['rule1']}>
            <HandwrittenText
              alt={buttonActionImg?.fields?.mediaAltText}
              content={
                isMobile
                  ? buttonActionImg?.fields?.mobileMediaContent
                  : buttonActionImg?.fields?.mediaContent
              }
            />
          </div>
        )}
      </Grid>
    )
  }

  function sectionVariant() {
    if (sectionVariation === 'carousel') {
      return PaperCarouselCards(grid?.gridItems, trackingNameCarouselControls)
    } else {
      return PaperCardBadge(
        badgeExample,
        exampleTitle,
        exampleLabelOne,
        exampleValueOne,
        exampleLabelTwo,
        exampleValueTwo,
        exampleLabelThree,
        exampleValueThree,
        exampleLabelFour,
        exampleValueFour
      )
    }
  }

  return (
    <>
      <TitleDivider
        className={styles['paper-carousel-title-divider']}
        eyebrowClassName={styles['paper-carousel-title-eyebrow']}
        eyebrow={eyebrow}
        eyebrowBlack
        subtitle={!isMobile && summary}
        title={title}
        isMobile={isMobile}
        data-testid="skinny-form-title"
        variants={variant}
        subtitleTag="p"
      />
      <Grid
        container
        data-testid="desktop-product-hero-container"
        className={styles['slider-container']}
      >
        <Grid container item xs={12} md={5} xl={6}>
          <Grid item xs={12} md={sectionVariation === 'carousel' ? 11 : 10}>
            <Paper className={styles['slider-tool-paper']} data-testid="skinny-form-container">
              <Typography
                content={sliderQuestion}
                variant={isMobile ? 'displayXS30' : 'displayS10'}
                className={styles['slider-tool-title']}
                data-testid="skinny-form-title"
              />
              <div className={styles['slider-tool-wrapper']}>
                <Typography
                  content={`$${dollarUSLocale.format(sliderValue)}${
                    sliderValue === slider.max && slider.maxPlus ? '+' : ''
                  }`}
                  variant="displayXS30"
                  fontWeight="medium"
                  className={styles['slider-tool-value']}
                  data-testid="product-hero-dollar-value"
                />
                <Slider
                  aria-label="dollar-amount-selection-slider"
                  classes={{
                    mark: styles['marks'],
                  }}
                  data-testid="skinny-form-input"
                  value={sliderValue}
                  step={slider.step}
                  min={slider.min}
                  max={slider.max}
                  marks={[
                    {
                      value: slider.min,
                      label: (
                        <Typography
                          content={`$${dollarUSLocale.format(slider.min)}`}
                          variant="displayXS30"
                          fontWeight="regular"
                          className={styles['slider-tool-min-mark']}
                          data-testid="skinny-form-min"
                        />
                      ),
                    },
                    {
                      value: slider.max,
                      label: (
                        <Typography
                          content={`$${dollarUSLocale.format(slider.max)}${
                            slider.maxPlus ? '+' : ''
                          }`}
                          variant="displayXS30"
                          fontWeight="regular"
                          className={styles['slider-tool-max-mark']}
                          data-testid="skinny-form-max"
                        />
                      ),
                    },
                  ]}
                  onChange={handleChange}
                />
              </div>
              <Divider margin={2} className={styles['slider-divider']} />
              <Typography
                content={sliderStatsTitle}
                variant={isMobile ? 'displayXS30' : 'displayS10'}
                className={styles['slider-tool-stats-title']}
                data-testid="skinny-form-stats-title"
                data-disabled={!changeSlider}
              />
              {changeSlider && (
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={ownHome}
                  onChange={handleChangeHome}
                  className={styles['home-owner-radio-row']}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              )}
              <Divider margin={2} className={styles['slider-divider']} />
              {ButtonContainer({ disabled: ownHome === null })}
            </Paper>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={sectionVariation === 'carousel' ? 6 : 5}
          xl={6}
          alignSelf="flex-start"
          position="relative"
        >
          {sectionVariant()}
          {disclaimer && (
            <Grid className={styles['disclaimer-container']}>
              <Typography
                content={disclaimer?.textContent}
                variant="displayXS20"
                data-testid="example-disclaimer"
              />
            </Grid>
          )}
          {isMobile && (
            <LinkButton
              className={styles['customize-button']}
              track={{
                list_name: 'PRODUCT FORM CTA',
              }}
              content={{
                linkHref: ctaCustomizeLink,
                linkText: ctaCustomizeText,
              }}
              typographicOptions={{ variant: 'displayS10', fontWeight: 'bold' }}
              color="primary"
              variant="text"
              data-testid="skinny-form-cta"
              fullWidth={isMobile}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

export { SkinnyFormSection, CardSkinnyCarousel }
export default SkinnyFormSection
