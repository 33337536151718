import styles from './SkinnyForm.module.scss'
import { Grid, CircularProgress, AchieveTheme } from '@achieve/sunbeam'
import { useEffect, useReducer } from 'react'
import { RecommendedCards } from './RecommendedCards'
import { SkinnyFormSection } from './SkinnyFormSection'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { useRef } from 'react'
import Section from 'components/Section'

const SKINNY_FORM = 'SKINNY_FORM'
const SKINNY_FORM_PROGRESS = 'SKINNY_FORM_PROGRESS'
const SKINNY_FORM_RESULTS = 'SKINNY_FORM_RESULTS'
const SKINNY_FORM_PROGRESS_DURATION = 1000

function SkinnyForm({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)
  const progressTimeoutRef = useRef(null)

  const initialState = {
    step: SKINNY_FORM,
    isBusy: false,
    formData: {
      formAmount: 0,
      formHomeowner: '',
      formAmountQueryParameter: '',
    },
  }

  function reducer(state, action) {
    switch (action.type) {
      case 'SET_STEP':
        return { ...state, step: action.payload }
      case 'SET_IS_BUSY':
        return { ...state, isBusy: action.payload }
      case 'SET_FORM_DATA':
        return { ...state, formData: action.payload, step: SKINNY_FORM_PROGRESS }
      default:
        throw new Error(`Unhandled action type: ${action.type}`)
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const { step, isBusy, formData } = state
  const onChangeSection = (payload) => dispatch({ type: 'SET_FORM_DATA', payload })

  useEffect(() => {
    dispatch({ type: 'SET_IS_BUSY', payload: step === SKINNY_FORM_PROGRESS })
    if (step === SKINNY_FORM_PROGRESS) {
      progressTimeoutRef.current = setTimeout(() => {
        dispatch({ type: 'SET_STEP', payload: SKINNY_FORM_RESULTS })
      }, SKINNY_FORM_PROGRESS_DURATION)
    } else {
      clearTimeout(progressTimeoutRef.current)
    }
  }, [step])

  return (
    <Section
      className={styles['section']}
      data-testid="skinny-form-section"
      data-variant-progress={isBusy}
    >
      {isBusy ? (
        // Show progress state
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={styles['skinny-form-progress-spinner']}
        >
          <CircularProgress
            style={{
              color: isMobile
                ? AchieveTheme?.sb?.colors?.neutral?.white
                : AchieveTheme?.sb?.colors?.primary?.achieveBlue,
            }}
          />
        </Grid>
      ) : step === SKINNY_FORM_RESULTS ? (
        <RecommendedCards
          content={content}
          onReset={() => dispatch({ type: 'SET_STEP', payload: SKINNY_FORM })}
          formAmount={formData.formAmount}
          formHomeowner={formData.formHomeowner}
          formAmountQueryParameter={formData.formAmountQueryParameter}
        />
      ) : (
        <SkinnyFormSection content={content} onChangeSection={onChangeSection} />
      )}
    </Section>
  )
}

export {
  SkinnyForm,
  SKINNY_FORM,
  SKINNY_FORM_PROGRESS,
  SKINNY_FORM_RESULTS,
  SKINNY_FORM_PROGRESS_DURATION,
}
export default SkinnyForm
