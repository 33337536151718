import { useMemoizedContentGetter } from 'utils/contentful'
import styles from './RecommendedCards.module.scss'
import { TitleDivider } from 'components/TitleDivider'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { Box, Link } from '@achieve/sunbeam'
import { TrackingWrapper } from 'components/Tracking/TrackingWrapper'
import { get as _get } from 'lodash-es'
import { ArrowLeft } from 'react-feather'
import { LinkButton, MediaImageStandard, Typography } from 'components/Contentful'
import { Eyebrow } from 'components/Eyebrow'
import { IconListItem } from 'components/IconListItem'
import { Carousel } from 'components/Carousels'
import { ANCHOR_POINTS, AchieveBadge } from 'components/AchieveBadge'
import { useEffect, useState } from 'react'

function RecommendedCards({
  content,
  onReset,
  formAmount,
  formHomeowner,
  formAmountQueryParameter,
}) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.xl)

  const [topChoice, setTopChoice] = useState('')
  const [orderCards, setOrderCards] = useState('')
  const [gridItems, setGridItems] = useState([])

  const { titleResult, eyebrowResult, gridResult, resetLink, badgeTopChoice, config } =
    useMemoizedContentGetter(content, [
      'titleResult',
      'eyebrowResult',
      'gridResult',
      'resetLink',
      'badgeTopChoice',
      'config',
    ])

  const formResults = _get(config, `jsonContent.formResults["${formHomeowner}"]`, null)

  useEffect(() => {
    let matchResult = null
    if (formResults) {
      let i = 0
      while (matchResult === null && i < formResults.length) {
        const element = formResults[i]
        if (formAmount >= element.start && formAmount <= (element.end ?? formAmount))
          matchResult = element
        i++
      }
    }
    setTopChoice(matchResult?.order?.[0] ?? '')
    setOrderCards(matchResult?.order)
  }, [formResults, formAmount, formHomeowner])

  useEffect(() => {
    let copyGridItems = JSON.parse(JSON.stringify(gridResult?.gridItems))
    if (orderCards) {
      copyGridItems = orderCards.map(
        (i) => copyGridItems[copyGridItems.map((e) => e?.fields?.fieldName).indexOf(i)]
      )
    }
    const listOrdered = []
    let matchChoice = null
    for (let i = 0; i < copyGridItems.length; i++) {
      const element = copyGridItems[i]
      if (element?.fields?.fieldName === topChoice) matchChoice = element
      else listOrdered.push(element)
    }
    if (topChoice && isMobile) {
      if (matchChoice) listOrdered.unshift(matchChoice)
      setGridItems(listOrdered)
    } else {
      if (matchChoice) listOrdered.splice(1, 0, matchChoice)
      setGridItems(listOrdered)
    }
  }, [topChoice, gridResult, isMobile, orderCards])

  const resetLinkText = _get(resetLink, 'textContent.content[0].content[0].value')

  const Card = ({ item, index, formAmount, formHomeowner }) => {
    const fields = item?.fields
    const eyebrowText = fields?.eyebrow
    const cardTitleText = _get(
      fields?.cardTitleText,
      'fields.textContent.content[0].content[0].value'
    )
    const cardLinkText = _get(fields?.cardLink, 'fields.linkText.content[0].content[0].value')
    const cardLinkHref =
      _get(fields?.cardLink, 'fields.linkHref') +
      `&${formAmountQueryParameter}=${formAmount}&is_home_owner=${formHomeowner}`
    return (
      <Box
        key={`item-${index}`}
        className={styles['cards-list-item']}
        data-top-choice={fields?.fieldName === topChoice}
      >
        {fields?.fieldName === topChoice && (
          <AchieveBadge
            content={badgeTopChoice?.mediaContent}
            height={70}
            width={70}
            anchor={ANCHOR_POINTS.RIGHT_TOP}
            translateX={-70}
            translateY={0}
          />
        )}
        <Box className={styles['cards-list-item-image']}>
          <MediaImageStandard
            alt={eyebrowText ?? fields?.cardImage?.fields?.title}
            priority={true}
            content={fields?.cardImage}
            layout="responsive"
          />
        </Box>
        {eyebrowText && (
          <Eyebrow
            className={styles['cards-list-item-eyebrow']}
            content={eyebrowText}
            variant="displayXS20"
            fontWeight="bold"
            black
          />
        )}
        <Typography content={fields?.cardTitleText} variant="displayS10" fontWeight="medium" />
        <Box className={styles['cards-list-item-icons']}>
          {fields?.iconCardItem.map((icon, iconIdx) => {
            return (
              <Box className={styles['cards-list-item-icons-item']} key={`item-icon-${iconIdx}`}>
                <IconListItem
                  key={`icon-list-item-${iconIdx}`}
                  cardTitleString={cardTitleText}
                  iconColor={icon.fields.iconColor}
                  iconName={icon.fields.iconName}
                  itemText={icon.fields.description}
                  classNameIcon={styles['icon']}
                  iconSize={20}
                  noMarginBottom={iconIdx + 1 === fields?.iconCardItem?.length}
                />
              </Box>
            )
          })}
        </Box>
        <LinkButton
          track={{
            list_name: 'Homepage Skinny Form Result Buttons',
            click_id: cardLinkText,
            click_text: cardLinkText,
            click_position: index,
            form_ss_amount: formAmount,
            form_ss_homeowner: formHomeowner,
            product_card_title: eyebrowText,
            product_card_recomended: fields?.fieldName === topChoice,
          }}
          content={{
            linkHref: cardLinkHref,
            linkText: cardLinkText,
          }}
          typographicOptions={{ variant: 'displayS10', fontWeight: 'bold' }}
          color="primary"
          variant="contained"
        />
      </Box>
    )
  }

  const itemsGrid = () => {
    return (
      <Box className={styles['cards-list']}>
        {gridItems.map((item, i) => (
          <Card
            key={`item-${i}`}
            item={item}
            index={i}
            formAmount={formAmount}
            formHomeowner={formHomeowner}
          />
        ))}
      </Box>
    )
  }

  const itemGridCarousel = () => {
    return (
      <Box data-variant="carousel">
        <Carousel
          carouselItems={gridItems}
          itemComponent={Card}
          variant="auto"
          idPrefix="value-props-carousel"
          withIndicators
          className={styles['carousel-wrapper']}
          classNameItems={styles['carousel-item']}
          classNameContainer={styles['carousel-container']}
          classNameIndicators={styles['indicators']}
          classNameActiveDot={styles['active-indicator']}
          dataItem={{
            formAmount,
            formHomeowner,
            topChoice: 'PL',
          }}
          disableArrows
        />
      </Box>
    )
  }

  return (
    <Box data-testid="skinny-form-section-recommended-cards">
      <TitleDivider
        className={styles['cards-title-divider']}
        eyebrowClassName={styles['cards-title-eyebrow']}
        eyebrow={eyebrowResult}
        eyebrowBlack
        // subtitle={!isMobile && summaryResult}
        title={titleResult}
        isMobile={isMobile}
        data-testid="skinny-form-title"
        variants={{
          title: isMobile ? 'displayS20' : 'displayM20',
          titleFontWeight: 'medium',
          subtitle: 'displayS10',
          subtitleFontWeight: 'regular',
        }}
        subtitleTag="p"
      />
      {isMobile ? itemGridCarousel() : itemsGrid()}
      <TrackingWrapper
        track={{
          list_name: 'Homepage Skinny Form Result',
          click_id: resetLinkText,
          click_text: resetLinkText,
          click_position: 4,
          form_ss_amount: formAmount,
          form_ss_homeowner: formHomeowner,
        }}
      >
        <Link
          className={styles['reset-link']}
          underline="hover"
          fontWeight="bold"
          variant="displayS10"
          data-testid="mad-lib-results-reset"
          tabIndex={0}
          onClick={() => onReset()}
        >
          <ArrowLeft /> <span>{resetLinkText}</span>
        </Link>
      </TrackingWrapper>
    </Box>
  )
}

export { RecommendedCards }
export default RecommendedCards
